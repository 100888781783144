@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
    border: none;
    box-sizing: border-box;
    margin: 0;
    text-decoration: none;
    padding: 0;
    line-height: 1;
    outline: none;
    font-family: Montserrat, sans-serif !important;
}

html,body,#root {
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.alice-carousel__stage {
    height: 275px;
}
.alice-carousel__stage-item {
    padding-left: 5px;
}

.alice-carousel__dots {
	height: 69px;
	overflow-x: auto;
	display: flex;
	cursor: -webkit-grab;
	cursor: grab;
}

.alice-carousel__dots-item {
	margin: 0px 5px;
}

.ReactModal__Overlay {
	z-index: 1200!important;
}

.ril-toolbar {
	background-color: transparent;
}

.ril__outer {
	z-index: 1400!important;
}

